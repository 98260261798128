<script>
import Layout from "../../layouts/main.vue";
import Live from "./Live.vue";
import Swal from "sweetalert2";

export default {
  data() {
    return {
    };
  },
  props: {
    data: {
      type: Object,
      default: function () {
        return {
        }
      }
    }
  },
  components: {
    Layout,
    Live
  },
  mounted() {
    this.$refs.pnlLive.isServerSide = true;
    this.$refs.pnlLive.accessMedia(true);
  },
  beforeRouteLeave(to, from, next) {
    if(this.$refs.pnlLive.visibleConnection) {
      console.log('Before route leave: ', to, from);
      Swal.fire({
        position: "top-end",
        icon: "warning",
        title: "You are in active live room session.",
        showConfirmButton: false,
        timer: 1500
      });
      next(false);
    }
    else
      next();
  },
  methods: {
  }
};
</script>

<template>
  <Layout>
    <div class="card">
      <div class="card-body">
        <Live ref="pnlLive"></Live>
      </div>
    </div>
  </Layout>
</template>
